<template>
  <div v-loading="loading" class="journalPortrait">
    <!-- 引用期刊分布 -->
    <div v-if="showYYQK" class="YYQK-distributed mb-20">
      <div class="echarts-title">
        <div class="second-title"><span>引用期刊分布</span></div>
        <div class="top">
          <div
            :class="YYQKActive === 'totalCount3,desc' ? 'active' : ''"
            class="mr-8"
            @click="YYQKActive = 'totalCount3,desc'"
            data-track="增值服务-发文分析-引用期刊分布-近3年"
          >
            近3年
          </div>
          <div
            :class="YYQKActive === 'totalCount5,desc' ? 'active' : ''"
            class="mr-8"
            @click="YYQKActive = 'totalCount5,desc'"
            data-track="增值服务-发文分析-引用期刊分布-近5年"
          >
            近5年
          </div>
        </div>
      </div>
      <div
        id="chartYYQK"
        :style="{
          width: '100%',
          height: '420px'
        }"
      />
    </div>
    <!-- 引证期刊分布 -->
    <div v-if="showYZQK" class="YZQK-distributed mb-20">
      <div class="echarts-title">
        <div class="second-title"><span>引证期刊分布</span></div>
        <div class="top">
          <div
            :class="YZQKActive === 'totalCount3,desc' ? 'active' : ''"
            class="mr-8"
            @click="YZQKActive = 'totalCount3,desc'"
            data-track="增值服务-发文分析-引证期刊分布-近3年"
          >
            近3年
          </div>
          <div
            :class="YZQKActive === 'totalCount5,desc' ? 'active' : ''"
            class="mr-8"
            @click="YZQKActive = 'totalCount5,desc'"
            data-track="增值服务-发文分析-引证期刊分布-近5年"
          >
            近5年
          </div>
        </div>
      </div>
      <div
        id="chartYZQK"
        :style="{
          width: '100%',
          height: '420px'
        }"
      />
    </div>
    <!-- 同领域期刊分布 -->
    <div v-if="showTLYQK" class="TLYQK-distributed mb-20">
      <div class="echarts-title">
        <div class="second-title"><span>同领域期刊分布</span></div>
        <div class="top">
          <div
            :class="TLYQKActive === 'totalCount3,desc' ? 'active' : ''"
            class="mr-8"
            @click="TLYQKActive = 'totalCount3,desc'"
            data-track="增值服务-发文分析-同领域期刊分布-近3年"
          >
            近3年
          </div>
          <div
            :class="TLYQKActive === 'totalCount5,desc' ? 'active' : ''"
            class="mr-8"
            @click="TLYQKActive = 'totalCount5,desc'"
            data-track="增值服务-发文分析-同领域期刊分布-近5年"
          >
            近5年
          </div>
        </div>
      </div>
      <div
        id="chartTLYQK"
        :style="{
          width: '100%',
          height: '420px'
        }"
      />
    </div>
    <!-- 本刊机构发文统计 -->
    <div
      v-if="JGFWTJTableData.length > 0"
      class="statistics-issued-by-institutions mb-20"
    >
      <div class="echarts-title">
        <div class="second-title"><span>本刊机构发文统计</span></div>
        <div class="top">
          <div
            :class="JGFWTJActive === 'totalCount3,desc' ? 'active' : ''"
            class="mr-8"
            @click="JGFWTJActive = 'totalCount3,desc'"
            data-track="增值服务-发文分析-本刊机构发文统计-近3年"
          >
            近3年
          </div>
          <div
            :class="JGFWTJActive === 'totalCount5,desc' ? 'active' : ''"
            class="mr-8"
            @click="JGFWTJActive = 'totalCount5,desc'"
            data-track="增值服务-发文分析-本刊机构发文统计-近5年"
          >
            近5年
          </div>
        </div>
      </div>
      <commonTable
        ref="JGFWTJTable"
        :table-data="JGFWTJTableData"
        :column-data="JGFWTJColumnData"
        :init-obj="JGFWTJPageObj"
        @onChangeSort="
          (prop, order) => {
            handleChangeSort(prop, order, 'JGFWTJ')
          }
        "
        @onChangePageData="
          (pageObj) => {
            getJGFWTJTableData(pageObj)
          }
        "
      />
    </div>
    <!-- 同领域机构发文统计 -->
    <div v-if="TLYJGTableData.length > 0" class="TLYJG mb-20">
      <div class="echarts-title">
        <div class="second-title"><span>同领域机构发文统计</span></div>
        <div class="top">
          <div
            :class="TLYJGActive === 'totalCount3,desc' ? 'active' : ''"
            class="mr-8"
            @click="TLYJGActive = 'totalCount3,desc'"
            data-track="增值服务-发文分析-同领域机构发文统计-近3年"
          >
            近3年
          </div>
          <div
            :class="TLYJGActive === 'totalCount5,desc' ? 'active' : ''"
            class="mr-8"
            @click="TLYJGActive = 'totalCount5,desc'"
            data-track="增值服务-发文分析-同领域机构发文统计-近5年"
          >
            近5年
          </div>
        </div>
      </div>
      <commonTable
        ref="TLYJGTable"
        :table-data="TLYJGTableData"
        :column-data="TLYJGColumnData"
        :init-obj="TLYJGPageObj"
        @onChangeSort="
          (prop, order) => {
            handleChangeSort(prop, order, 'TLYJG')
          }
        "
        @onChangePageData="
          (pageObj) => {
            getTLYJGTableData(pageObj)
          }
        "
      />
    </div>
    <!-- 引用机构分布 -->
    <div v-if="YYJGTableData.length > 0" class="YYJG-distributed mb-20">
      <div class="echarts-title">
        <div class="second-title"><span>引用机构分布</span></div>
        <div class="top">
          <div
            :class="YYJGActive === 'totalCount3,desc' ? 'active' : ''"
            class="mr-8"
            @click="YYJGActive = 'totalCount3,desc'"
            data-track="增值服务-发文分析-引用机构分布-近3年"
          >
            近3年
          </div>
          <div
            :class="YYJGActive === 'totalCount5,desc' ? 'active' : ''"
            class="mr-8"
            @click="YYJGActive = 'totalCount5,desc'"
            data-track="增值服务-发文分析-引用机构分布-近5年"
          >
            近5年
          </div>
        </div>
      </div>
      <commonTable
        ref="YYJGTable"
        :table-data="YYJGTableData"
        :column-data="YYJGColumnData"
        :init-obj="YYJGPageObj"
        @onChangeSort="
          (prop, order) => {
            handleChangeSort(prop, order, 'YYJG')
          }
        "
        @onChangePageData="
          (pageObj) => {
            getYYJGTableData(pageObj)
          }
        "
      />
    </div>
    <!-- 引证机构分布 -->
    <div v-if="YZJGTableData.length > 0" class="YZJG-distributed mb-20">
      <div class="echarts-title">
        <div class="second-title"><span>引证机构分布</span></div>
        <div class="top">
          <div
            :class="YZJGActive === 'totalCount3,desc' ? 'active' : ''"
            class="mr-8"
            @click="YZJGActive = 'totalCount3,desc'"
            data-track="增值服务-发文分析-引证机构分布-近3年"
          >
            近3年
          </div>
          <div
            :class="YZJGActive === 'totalCount5,desc' ? 'active' : ''"
            class="mr-8"
            @click="YZJGActive = 'totalCount5,desc'"
            data-track="增值服务-发文分析-引证机构分布-近5年"
          >
            近5年
          </div>
        </div>
      </div>
      <commonTable
        ref="YZJGTable"
        :table-data="YZJGTableData"
        :column-data="YZJGColumnData"
        :init-obj="YZJGPageObj"
        @onChangeSort="
          (prop, order) => {
            handleChangeSort(prop, order, 'YZJG')
          }
        "
        @onChangePageData="
          (pageObj) => {
            getYZJGTableData(pageObj)
          }
        "
      />
    </div>
    <!-- 关键词统计 -->
    <div v-if="keywordsTableData.length > 0" class="keywords mb-20">
      <div class="echarts-title">
        <div class="second-title"><span>关键词统计</span></div>
        <div class="top">
          <div
            :class="keywordsActive === 'totalCount3,desc' ? 'active' : ''"
            class="mr-8"
            @click="keywordsActive = 'totalCount3,desc'"
            data-track="增值服务-发文分析-关键词统计-近3年"
          >
            近3年
          </div>
          <div
            :class="keywordsActive === 'totalCount5,desc' ? 'active' : ''"
            class="mr-8"
            @click="keywordsActive = 'totalCount5,desc'"
            data-track="增值服务-发文分析-关键词统计-近5年"
          >
            近5年
          </div>
        </div>
      </div>
      <commonTable
        ref="keywordsTable"
        :table-data="keywordsTableData"
        :column-data="keywordsColumnData"
        :init-obj="keywordsPageObj"
        @onChangeSort="
          (prop, order) => {
            handleChangeSort(prop, order, 'keywords')
          }
        "
        @onChangePageData="
          (pageObj) => {
            getKeywordsTableData(pageObj)
          }
        "
      />
    </div>
    <!-- 共现词统计 -->
    <div v-if="coocwordsTableData.length > 0" class="coocwords mb-20">
      <div class="echarts-title">
        <div class="second-title"><span>共现词统计</span></div>
        <div class="top">
          <div
            :class="coocwordsActive === 'totalCount3,desc' ? 'active' : ''"
            class="mr-8"
            @click="coocwordsActive = 'totalCount3,desc'"
            data-track="增值服务-发文分析-共现词统计-近3年"
          >
            近3年
          </div>
          <div
            :class="coocwordsActive === 'totalCount5,desc' ? 'active' : ''"
            class="mr-8"
            @click="coocwordsActive = 'totalCount5,desc'"
            data-track="增值服务-发文分析-共现词统计-近5年"
          >
            近5年
          </div>
        </div>
      </div>
      <commonTable
        ref="coocwordsTable"
        :table-data="coocwordsTableData"
        :column-data="coocwordsColumnData"
        :init-obj="coocwordsPageObj"
        @onChangeSort="
          (prop, order) => {
            handleChangeSort(prop, order, 'coocwords')
          }
        "
        @onChangePageData="
          (pageObj) => {
            getCoocwordsTableData(pageObj)
          }
        "
      />
    </div>
  </div>
</template>
<script>
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
import { getRecentYears } from '../../assets/js/utils'
import commonTable from '../../components/commonTable'
import { mapState } from 'vuex'
import echarts from 'echarts'
const api = ProjectConfig.emailPush
/* eslint-disable */
export default {
  name: 'PushUserData',
  components: {
    commonTable
  },
  data() {
    return {
      loading: true,
      YYQKActive: 'totalCount5,desc',
      YZQKActive: 'totalCount5,desc',
      TLYQKActive: 'totalCount5,desc',
      YYJGActive: 'totalCount5,desc',
      YZJGActive: 'totalCount5,desc',
      JGFWTJActive: 'totalCount5,desc',
      keywordsActive: 'totalCount5,desc',
      coocwordsActive: 'totalCount5,desc',
      TLYJGActive: 'totalCount5,desc',
      YYJGTableData: [],
      YYJGColumnFive: [
        {
          title: '引用机构',
          prop: 'institutionName',
          width: '190px',
          notSortable: true
        },
        ...getRecentYears(5, 'prop', 'title'),
        {
          title: '引用合计',
          prop: 'totalCount5'
        }
      ],
      YYJGColumnThree: [
        {
          title: '引用机构',
          prop: 'institutionName',
          width: '300px',
          notSortable: true
        },
        ...getRecentYears(3, 'prop', 'title'),
        {
          title: '引用合计',
          prop: 'totalCount3'
        }
      ],
      YYJGPageObj: {
        page: 1,
        size: 10,
        sort: 'totalCount5,desc'
      },
      YZJGTableData: [],
      YZJGColumnFive: [
        {
          title: '引证机构',
          prop: 'institutionName',
          width: '190px',
          notSortable: true
        },
        ...getRecentYears(5, 'prop', 'title'),
        {
          title: '引证合计',
          prop: 'totalCount5'
        }
      ],
      YZJGColumnThree: [
        {
          title: '引证机构',
          prop: 'institutionName',
          width: '300px',
          notSortable: true
        },
        ...getRecentYears(3, 'prop', 'title'),
        {
          title: '引证合计',
          prop: 'totalCount3'
        }
      ],
      YZJGPageObj: {
        page: 1,
        size: 10,
        sort: 'totalCount5,desc'
      },
      JGFWTJTableData: [],
      JGFWTJColumnFive: [
        {
          title: '发文机构',
          prop: 'institutionName',
          width: '190px',
          notSortable: true
        },
        ...getRecentYears(5, 'prop', 'title'),
        {
          title: '发文合计',
          prop: 'totalCount5'
        },
        {
          title: '被引合计',
          prop: 'referedCount5'
        },
        {
          title: '单篇最高被引',
          width: '150px',
          prop: 'singleReferedCount5'
        }
      ],
      JGFWTJColumnThree: [
        {
          title: '发文机构',
          prop: 'institutionName',
          width: '300px',
          notSortable: true
        },
        ...getRecentYears(3, 'prop', 'title'),
        {
          title: '发文合计',
          prop: 'totalCount3'
        },
        {
          title: '被引合计',
          prop: 'referedCount3'
        },
        {
          title: '单篇最高被引',
          width: '150px',
          prop: 'singleReferedCount3'
        }
      ],
      JGFWTJPageObj: {
        page: 1,
        size: 10,
        sort: 'totalCount5,desc'
      },
      YYQKData: '',
      YZQKData: '',
      TLYQKData: '',
      keywordsTableData: [],
      keywordsColumnFive: [
        {
          title: '关键词',
          prop: 'keywords',
          width: '100px',
          notSortable: true
        },
        ...getRecentYears(5, 'prop', 'title'),
        {
          title: '近3年合计',
          prop: 'totalCount3'
        },
        {
          title: '近5年合计',
          prop: 'totalCount5'
        }
      ],
      keywordsColumnThree: [
        {
          title: '关键词',
          prop: 'keywords',
          width: '190px',
          notSortable: true
        },
        ...getRecentYears(3, 'prop', 'title'),
        {
          title: '近3年合计',
          prop: 'totalCount3'
        },
        {
          title: '近5年合计',
          prop: 'totalCount5'
        }
      ],
      keywordsPageObj: {
        page: 1,
        size: 10,
        sort: 'totalCount5,desc'
      },
      coocwordsTableData: [],
      coocwordsColumnFive: [
        {
          title: '共现词',
          prop: 'cooccurrenceWords',
          notSortable: true
        },
        ...getRecentYears(5, 'prop', 'title'),
        {
          title: '近3年合计',
          prop: 'totalCount3'
        },
        {
          title: '近5年合计',
          prop: 'totalCount5'
        }
      ],
      coocwordsColumnThree: [
        {
          title: '共现词',
          prop: 'cooccurrenceWords',
          notSortable: true
        },
        ...getRecentYears(3, 'prop', 'title'),
        {
          title: '近3年合计',
          prop: 'totalCount3'
        },
        {
          title: '近5年合计',
          prop: 'totalCount5'
        }
      ],
      coocwordsPageObj: {
        page: 1,
        size: 10,
        sort: 'totalCount5,desc'
      },
      TLYJGTableData: [],
      TLYJGColumnFive: [
        {
          title: '同领域机构',
          prop: 'institutionName',
          notSortable: true
        },
        ...getRecentYears(5, 'prop', 'title'),
        {
          title: '近3年合计',
          prop: 'totalCount3'
        },
        {
          title: '近5年合计',
          prop: 'totalCount5'
        }
      ],
      TLYJGColumnThree: [
        {
          title: '同领域机构',
          prop: 'institutionName',
          notSortable: true
        },
        ...getRecentYears(3, 'prop', 'title'),
        {
          title: '近3年合计',
          prop: 'totalCount3'
        },
        {
          title: '近5年合计',
          prop: 'totalCount5'
        }
      ],
      TLYJGPageObj: {
        page: 1,
        size: 10,
        sort: 'totalCount5,desc'
      }
    }
  },
  computed: {
    ...mapState('incrementService', ['magId']),
    YYJGColumnData() {
      return this.YYJGActive === 'totalCount5,desc'
        ? this.YYJGColumnFive
        : this.YYJGColumnThree
    },
    YZJGColumnData() {
      return this.YZJGActive === 'totalCount5,desc'
        ? this.YZJGColumnFive
        : this.YZJGColumnThree
    },
    JGFWTJColumnData() {
      return this.JGFWTJActive === 'totalCount5,desc'
        ? this.JGFWTJColumnFive
        : this.JGFWTJColumnThree
    },
    keywordsColumnData() {
      return this.keywordsActive === 'totalCount5,desc'
        ? this.keywordsColumnFive
        : this.keywordsColumnThree
    },
    coocwordsColumnData() {
      return this.coocwordsActive === 'totalCount5,desc'
        ? this.coocwordsColumnFive
        : this.coocwordsColumnThree
    },
    TLYJGColumnData() {
      return this.TLYJGActive === 'totalCount5,desc'
        ? this.TLYJGColumnFive
        : this.TLYJGColumnThree
    },
    showYYQK() {
      return (
        this.YYQKData &&
        this.YYQKData.content &&
        this.YYQKData.content.length > 0
      )
    },
    showYZQK() {
      return (
        this.YZQKData &&
        this.YZQKData.content &&
        this.YZQKData.content.length > 0
      )
    },
    showTLYQK() {
      return (
        this.TLYQKData &&
        this.TLYQKData.content &&
        this.TLYQKData.content.length > 0
      )
    }
  },
  watch: {
    magId(n) {
      if (n) {
        this.$nextTick(() => {
          this.getData()
        })
      } else {
        this.$nextTick(() => {
          this.initData()
        })
      }
    },
    YYQKActive(n) {
      if (n) {
        this.getChartData(
          'YYQK',
          {
            magId: this.magId,
            page: 0,
            size: 15,
            sort: this.YYQKActive
          },
          api.ReferenceMag.url
        )
      }
    },
    YZQKActive(n) {
      if (n) {
        this.getChartData(
          'YZQK',
          {
            magId: this.magId,
            page: 0,
            size: 15,
            sort: this.YZQKActive
          },
          api.CitationMag.url
        )
      }
    },
    TLYQKActive(n) {
      if (n) {
        this.getChartData(
          'TLYQK',
          {
            magId: this.magId,
            page: 0,
            size: 15,
            sort: this.TLYQKActive
          },
          api.ZxsMag.url
        )
      }
    },
    YYJGActive(n) {
      if (n) {
        this.YYJGPageObj.sort = n
        this.getYYJGTableData()
        this.$refs.YYJGTable.clearSort()
      }
    },
    YZJGActive(n) {
      if (n) {
        this.YZJGPageObj.sort = n
        this.getYZJGTableData()
        this.$refs.YZJGTable.clearSort()
      }
    },
    JGFWTJActive(n) {
      if (n) {
        this.JGFWTJPageObj.sort = n
        this.getJGFWTJTableData()
        this.$refs.JGFWTJTable.clearSort()
      }
    },
    keywordsActive(n) {
      if (n) {
        this.keywordsPageObj.sort = n
        this.getKeywordsTableData()
        this.$refs.keywordsTable.clearSort()
      }
    },
    coocwordsActive(n) {
      if (n) {
        this.coocwordsPageObj.sort = n
        this.getCoocwordsTableData()
        this.$refs.coocwordsTable.clearSort()
      }
    },
    TLYJGActive(n) {
      if (n) {
        this.TLYJGPageObj.sort = n
        this.getTLYJGTableData()
        this.$refs.TLYJGTable.clearSort()
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.adjustTable)
    if (this.magId) {
      this.$nextTick(() => {
        this.getData()
      })
    } else {
      this.loading = false
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustTable)
  },
  methods: {
    // 窗口大小改变重新渲染echarts
    adjustTable() {
      if (document.getElementById('chartYYQK')) {
        echarts.init(document.getElementById('chartYYQK')).resize()
      }
      if (document.getElementById('chartYZQK')) {
        echarts.init(document.getElementById('chartYZQK')).resize()
      }
    },
    initData() {
      this.chartYYQK()
      this.chartYZQK()
      this.YYJGTableData = []
      this.YZJGTableData = []
      this.JGFWTJTableData = []
      this.keywordsTableData = []
      this.coocwordsTableData = []
      this.TLYJGTableData = []
    },
    getData() {
      // 引用机构分布
      this.getYYJGTableData()
      // 引证机构分布
      this.getYZJGTableData()
      // 机构发文统计
      this.getJGFWTJTableData()
      // 关键词统计
      this.getKeywordsTableData()
      // 共现词统计
      this.getCoocwordsTableData()
      // 同领域机构统计
      this.getTLYJGTableData()
      // 引用期刊分布
      this.getChartData(
        'YYQK',
        {
          magId: this.magId,
          page: 0,
          size: 15,
          sort: this.YYQKActive
        },
        api.ReferenceMag.url
      )
      // 引证期刊分布
      this.getChartData(
        'YZQK',
        {
          magId: this.magId,
          page: 0,
          size: 15,
          sort: this.YZQKActive
        },
        api.CitationMag.url
      )
      // 同领域期刊分布
      this.getChartData(
        'TLYQK',
        {
          magId: this.magId,
          page: 0,
          size: 15,
          sort: this.TLYQKActive
        },
        api.ZxsMag.url
      )
    },
    // 获取echarts数据
    getChartData(type, params, url) {
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.loading = false
          const { data } = res.data
          let _this = this
          this.$nextTick(() => {
            if (type === 'YYQK') {
              _this.YYQKData = data
              if (_this.showYYQK) {
                _this.$nextTick(() => {
                  _this.chartYYQK(data)
                })
              }
            } else if (type === 'YZQK') {
              _this.YZQKData = data
              if (_this.showYZQK) {
                _this.$nextTick(() => {
                  _this.chartYZQK(data)
                })
              }
            } else if (type === 'TLYQK') {
              _this.TLYQKData = data
              if (_this.showTLYQK) {
                _this.$nextTick(() => {
                  _this.chartTLYQK(data)
                })
              }
            }
          })
        }
      })
    },
    // 引用期刊分布
    chartYYQK(data) {
      if (!document.getElementById('chartYYQK')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartYYQK'))
      const titleArr = []
      const threeYearsArr = []
      const fiveYearsArr = []
      const impactFactorArr = []
      if (data && data.content.length > 0) {
        data.content.map((item) => {
          titleArr.push(item.refMagName)
          threeYearsArr.push(item.totalCount3)
          fiveYearsArr.push(item.totalCount5)
          impactFactorArr.push(item.influence1)
        })
      }
      myChart.setOption(
        {
          color: ['#BBE0E3', '#0070C0', '#F7B8A9'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            orient: 'vertical',
            left: 'center',
            bottom: 'bottom',
            data: ['近3年引用合计', '近5年引用合计', '影响因子']
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '20%',
            top: '5%'
          },
          yAxis: [
            {
              type: 'value'
            },
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '近3年引用合计',
              type: 'bar',
              data: threeYearsArr,
              barWidth: 20
            },
            {
              name: '近5年引用合计',
              type: 'bar',
              data: fiveYearsArr,
              barWidth: 20
            },
            {
              name: '影响因子',
              type: 'line',
              yAxisIndex: 1,
              data: impactFactorArr,
              itemStyle: { normal: { label: { show: true } } }
            }
          ]
        },
        true
      )
    },
    // 引证期刊分布
    chartYZQK(data) {
      if (!document.getElementById('chartYZQK')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartYZQK'))
      const titleArr = []
      const threeYearsArr = []
      const fiveYearsArr = []
      const impactFactorArr = []
      if (data && data.content.length > 0) {
        data.content.map((item) => {
          titleArr.push(item.ctiMagName)
          threeYearsArr.push(item.totalCount3)
          fiveYearsArr.push(item.totalCount5)
          impactFactorArr.push(item.influence1)
        })
      }
      myChart.setOption(
        {
          color: ['#BBE0E3', '#0070C0', '#F7B8A9'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            orient: 'vertical',
            left: 'center',
            bottom: 'bottom',
            data: ['近3年引证合计', '近5年引证合计', '影响因子']
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '20%',
            top: '5%'
          },
          yAxis: [
            {
              type: 'value'
            },
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '近3年引证合计',
              type: 'bar',
              data: threeYearsArr,
              barWidth: 20
            },
            {
              name: '近5年引证合计',
              type: 'bar',
              data: fiveYearsArr,
              barWidth: 20
            },
            {
              name: '影响因子',
              type: 'line',
              yAxisIndex: 1,
              data: impactFactorArr,
              itemStyle: { normal: { label: { show: true } } }
            }
          ]
        },
        true
      )
    },
    // 同领域期刊分布
    chartTLYQK(data) {
      if (!document.getElementById('chartTLYQK')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartTLYQK'))
      const titleArr = []
      const threeYearsArr = []
      const fiveYearsArr = []
      const impactFactorArr = []
      if (data && data.content.length > 0) {
        data.content.map((item) => {
          titleArr.push(item.magName)
          threeYearsArr.push(item.totalCount3)
          fiveYearsArr.push(item.totalCount5)
          impactFactorArr.push(item.influence1)
        })
      }
      myChart.setOption(
        {
          color: ['#BBE0E3', '#0070C0', '#F7B8A9'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            orient: 'vertical',
            left: 'center',
            bottom: 'bottom',
            data: ['近3年发文合计', '近5年发文合计', '影响因子']
          },
          xAxis: [
            {
              type: 'category',
              data: titleArr,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                rotate: 30,
                formatter: function (value) {
                  var res = value
                  if (res.length > 6) {
                    res = res.substring(0, 6) + '..'
                  }
                  return res
                }
              }
            }
          ],
          grid: {
            left: '10%',
            bottom: '20%',
            top: '5%'
          },
          yAxis: [
            {
              type: 'value'
            },
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '近3年发文合计',
              type: 'bar',
              data: threeYearsArr,
              barWidth: 20
            },
            {
              name: '近5年发文合计',
              type: 'bar',
              data: fiveYearsArr,
              barWidth: 20
            },
            {
              name: '影响因子',
              type: 'line',
              yAxisIndex: 1,
              data: impactFactorArr,
              itemStyle: { normal: { label: { show: true } } }
            }
          ]
        },
        true
      )
    },
    // 引用机构分布
    getYYJGTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort: this.YYJGPageObj.sort,
        top: 100
      }
      let url = api.ReferenceInstitution.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.YYJGPageObj = {
            ...this.YYJGPageObj,
            page,
            size,
            total: totalElements
          }
          this.YYJGTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 引证机构分布
    getYZJGTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort: this.YZJGPageObj.sort,
        top: 100
      }
      let url = api.CitationInstitution.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.YZJGPageObj = {
            ...this.YZJGPageObj,
            page,
            size,
            total: totalElements
          }
          this.YZJGTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 机构发文统计
    getJGFWTJTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        type: 'mag',
        sort: this.JGFWTJPageObj.sort,
        top: 100
      }
      let url = api.Institution.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.JGFWTJPageObj = {
            ...this.JGFWTJPageObj,
            page,
            size,
            total: totalElements
          }
          this.JGFWTJTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 关键词统计
    getKeywordsTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort: this.keywordsPageObj.sort
      }
      let url = api.GetKeywordsStatistics.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.keywordsPageObj = {
            ...this.keywordsPageObj,
            page,
            size,
            total: totalElements
          }
          this.keywordsTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 共现词统计
    getCoocwordsTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort: this.coocwordsPageObj.sort
      }
      let url = api.GetCoocwordsStatistics.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.coocwordsPageObj = {
            ...this.coocwordsPageObj,
            page,
            size,
            total: totalElements
          }
          this.coocwordsTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 同领域机构
    getTLYJGTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort: this.TLYJGPageObj.sort
      }
      let url = api.ZxsInstitution .url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.TLYJGPageObj = {
            ...this.TLYJGPageObj,
            page,
            size,
            total: totalElements
          }
          this.TLYJGTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 改变排序
    handleChangeSort(prop, order, tableName) {
      if (tableName === 'YYJG') {
        // 引用机构分布排序改变
        let sortValue = ''
        if (!order || order === '') {
          sortValue = this.YYJGActive
        } else {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.YYJGPageObj.sort = sortValue
        this.getYYJGTableData()
      } else if (tableName === 'YZJG') {
        // 引证机构分布排序改变
        let sortValue = ''
        if (!order || order === '') {
          sortValue = this.YZJGActive
        } else {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.YZJGPageObj.sort = sortValue
        this.getYZJGTableData()
      } else if (tableName === 'JGFWTJ') {
        // 机构发文统计排序改变
        let sortValue = ''
        if (!order || order === '') {
          sortValue = this.JGFWTJActive
        } else {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.JGFWTJPageObj.sort = sortValue
        this.getJGFWTJTableData()
      } else if (tableName === 'keywords') {
        // 关键词统计排序改变
        let sortValue = ''
        if (!order || order === '') {
          sortValue = this.keywordsActive
        } else {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.keywordsPageObj.sort = sortValue
        this.getKeywordsTableData()
      } else if (tableName === 'coocwords') {
        // 共现词统计排序改变
        let sortValue = ''
        if (!order || order === '') {
          sortValue = this.coocwordsActive
        } else {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.coocwordsPageObj.sort = sortValue
        this.getCoocwordsTableData()
      } else if (tableName === 'TLYJG') {
        // 同领域机构统计排序改变
        let sortValue = ''
        if (!order || order === '') {
          sortValue = this.TLYJGActive
        } else {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.TLYJGPageObj.sort = sortValue
        this.getTLYJGTableData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.journalPortrait {
  padding: 0px 30px;
}
</style>
